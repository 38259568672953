import './form.css';

import React from 'react';

import Message from '../Message/Message';
import Button from '../Button/Button';

function Form({ children, onSubmit, submitButtonLabel = 'Opgeven', isLoading, error }) {
	const handleSubmit = (event) => {
		event.preventDefault();
		if (typeof onSubmit === 'function') {
			onSubmit(event);
		}
	};

	return (
		<div className={'form-container' + (isLoading ? ' is-loading' : '')}>
			<form noValidate onSubmit={handleSubmit}>
				<div className="form-body-container">
					{children}
				</div>
				<div className="form-footer-container">
					{error ?
						<Message type="error">{error.message}</Message>: ''}
					<Button type="primary" isLoading={isLoading}>{submitButtonLabel}</Button>
				</div>
			</form>
		</div>
	);
}

export default Form;
