import './header.css';
import logo from '../../assets/images/logo.svg';

import React from 'react';

function Header() {
	return (
		<header className="header-wrapper">
			<div className="header-content">
				<div className="header-logo-wrapper">
					<a className="header-logo" href="https://judolosser.nl">
						<img src={logo} alt="Judo Losser" />
					</a>
				</div>
			</div>
		</header>
	);
}

export default Header;
