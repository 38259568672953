import './button.css';

function Button({ children, type, isGhost, isLoading }) {
	return (
		<button className={'button' + (type ? ' is-' + type : '') + (isGhost ? ' is-ghost' : '') + (isLoading ? ' is-loading' : '')}>
			<span className="button-label">{children}</span>
		</button>
	);
}

export default Button;
