import './register-form.css';

import React, { useCallback, useState } from 'react';

import Form from '../Form/Form';
import InputField from '../InputField/InputField';
import Message from '../Message/Message';

import { useMutation, gql } from '@apollo/client';

const REGISTER_FOR_EVENT = gql`
	mutation registerForEvent($wpEventId: Int! $email: String!) {
		registered: registerForEvent(wpEventId: $wpEventId email: $email) {
			email
		}
	}
`;

const getFieldByName = (name, fields) => {
	const foundFields = fields.filter(field => field.name === name);
	return foundFields.length === 1 ? foundFields[0] : null;
};

function RegisterForm({ event }) {
	const [register, { data: registerPayLoad, loading, error }] = useMutation(
		REGISTER_FOR_EVENT,
		{ errorPolicy: 'all' }
	);

	const [fields, setFields] = useState([
		{
			type: 'email',
			label: 'E-mail',
			name: 'email',
			value: '',
			placeHolder: 'Wordt gebruikt om je op de hoogte te houden van het evenement'
		}
	]);

	const handleInputChange = useCallback((name, value) => {
		setFields(fields.map(field => ({
			...field,
			value: name === field.name ? value : field.value
		})));
	}, [fields]);

	const handleFormSubmit = useCallback(() => {
		const emailField = getFieldByName('email', fields);

		register({
			variables: {
				wpEventId: parseInt(event.wpId, 10),
				email: emailField.value
			}
		});
	}, [register, event, fields]);

	const date = new Date(event.date * 1000);

	return (
		<div className="register-form-container">
			{event.isRegistrationEnabled && (!registerPayLoad || !registerPayLoad.registered) ?
				<h1 className="register-form-title">Geef je op voor <strong>{event.name}</strong></h1> :
				<h1 className="register-form-title">{event.name}</h1>}
			<time className="register-form-event-date">{date.toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}</time>
			{event.shortDescription ?
				<p>{event.shortDescription}</p>
				: ''}
			{event.isRegistrationEnabled && (!registerPayLoad || !registerPayLoad.registered) ?
				<Form onSubmit={handleFormSubmit} error={error} isLoading={loading} >
					{fields.map(field => (
						<InputField key={field.name} {...field} onChange={handleInputChange} />
					))}
				</Form> : registerPayLoad && registerPayLoad.registered && registerPayLoad.registered.email ? <Message type="success">Bedankt! Je hebt je aangemeld met <strong>{registerPayLoad.registered.email}</strong></Message>  : <Message>Je kunt je niet registreren voor dit evenement</Message>}
		</div>
	);
}

export default RegisterForm;
