import './app.css';
import Header from './components/Header/Header';

import PhotoHeader from './components/PhotoHeader/PhotoHeader';

import RegisterForm from './components/RegisterForm/RegisterForm';
import RegisterFormSkeleton from './components/RegisterFormSkeleton/RegisterFormSkeleton';
import Message from './components/Message/Message';

import { gql, useQuery } from '@apollo/client';

const getEventIdFromHref = (href) => {
	return new URL(href).searchParams.get('event-id');
};

const GET_EVENT = gql`
	query getEvent($wpId: ID!) {
		event: getEvent(wpId: $wpId) {
			wpId
			name
			date
			featuredPhoto {
				src
				width
				height
				alt
			}
			shortDescription
			isRegistrationEnabled
			isPastEvent
		}
	}
`;

function App() {
	const { loading, error, data } = useQuery(GET_EVENT, {
		variables: { wpId: getEventIdFromHref(window.location.href) },
    	skip: !getEventIdFromHref(window.location.href)
	});

	return (
		<div className="event-registration-app-container">
			<Header />
			{data && data.event ?
				<PhotoHeader {...data.event.featuredPhoto} /> : ''}
			<main className="main-container">
				<div className="article content">
					{data && data.event ?
						data.event.isPastEvent ?
							<Message>Het evenement heeft al plaatsgevonden</Message> :
							<RegisterForm event={data.event} />
						: loading ? <RegisterFormSkeleton /> :
							error ? <Message type="error">{error.message}</Message> :
								<Message>Geen evenement gevonden</Message>}
				</div>
			</main>
		</div>
	);
}

export default App;
