import './photo-header.css';
import React from 'react';

function PhotoHeader( image ) {
	return (
		<div className="photo-header-container">
			<img {...image} alt={image.alt} />
		</div>
	);
}

export default PhotoHeader;