import './register-form-skeleton.css';

import React from 'react';

function RegisterFormSkeleton() {
	return (
		<div className="register-form-skeleton-container">
			<div className="skeleton-title is-skeleton"></div>
			<div className="skeleton-date is-skeleton"></div>
			<div className="skeleton-short-description is-skeleton"></div>
			<div className="skeleton-form-container">
				<div className="skeleton-input-field is-skeleton"></div>
				<div className="skeleton-input-field is-skeleton"></div>
				<div className="skeleton-input-field is-skeleton"></div>
			</div>
		</div>
	);
}

export default RegisterFormSkeleton;
